<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div>
        <router-link to="/mobile-tablet/create-model">
          <b-button variant="primary">
            <span class="align-middle">Add New Model</span>
          </b-button>
        </router-link>
      </div>
      <!-- <div>
        <router-link to="/mobile-tablet/deleted-models">
          <b-button
            variant="danger"
            size="md"
          >
            <span class="align-middle">Show deleted Models</span>
          </b-button>
        </router-link>
      </div> -->
    </div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-card title="Models">
        <b-row
          class="d-flex justify-content-end"
        >
          <b-col
            md="6"
            class="my-1 mx-0 px-0  d-flex justify-content-end "
            sm="4"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
              class="mb-0t mr-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          responsive="sm"
          :busy.sync="isBusy"
          :items="items"
          :fields="fields"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(ID)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.value }}
          </template>
          <template #cell(priority)="data">
            {{ data.value }}
          </template>
          <template #cell(brand)="data">
            {{ data.value }}
          </template>
          <template #cell(actions)="data">
            <div class="d-flex">
              <router-link :to="'/mobile-tablet/update-model/' + data.item.id">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </router-link>
              <b-button
                variant="flat-danger"
                class="btn-icon"
                @click="deleteModel(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <b-card-footer class="d-flex">
          <b-row class="w-100">
            <b-col
              md="6"
            >
              <b-form-group
                label-cols="4"
                label-cols-md="2"
                label="Per Page"
                label-for="perpage"
              >
                <b-form-select
                  id="perpage"
                  v-model="perPage"
                  :options="option"
                  class="w-md-25 w-25"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <div class="d-flex justify-content-md-end justify-content-start">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                />
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      isBusy: false,
      fields: [
        { key: 'ID', label: 'ID' },
        { key: 'name', label: 'name' },
        { key: 'priority', label: 'priority' },
        { key: 'mobile_brand.name', label: 'brand' },
        { key: 'actions', label: 'actions' },
      ],
      items: [],
      // Per Page & Pagination
      perPage: 20,
      currentPage: 1,
      option: [
        { value: 20, text: '20' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      totalRows: 1,
      // Filter
      filter: '',
    }
  },
  async mounted() {
    await this.getModels()
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_MODELS', 'DELETE_MODEL']),

    async getModels() {
      try {
        this.isBusy = true
        this.items = await this.SHOW_ALL_MODELS()
        this.totalRows = this.items.length
        this.isBusy = false
      } catch (error) {
        this.isBusy = false
      }
    },
    async deleteModel(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this model', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.loader = true
              const resp = await this.DELETE_MODEL(item.id)
              this.loader = false
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Model deleted!',
                    icon: 'PlusIcon',
                    variant: 'success',
                    text: 'Model has been deleted successfully!',
                  },
                })
                await this.getModels()
              }
            } catch (error) {
              this.items = []
              this.totalRows = 1
              this.loader = false
            }
          }
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
